import { Header } from "@components/Header";
import { Loading } from "@components/Loading";
import PaymentSuccessPopup from '@components/Popup/PaymentSuccessPopup'; // 올바른 경로로 수정
import { PaymentContext } from "@context/PaymentContext";
import { UserContext } from "@context/UserContext";
import { useAppSelector } from "@store";
import { ReactComponent as CircleC } from "@svg/CircleC.svg";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmPopup from "../../components/Popup/ConfirmPopup";
import LPointCardForm from '../../components/Popup/LPointCardForm';
import { useGetLPointBalanceMutation } from '../../redux/services/payments';

interface DataItem {
  Id: string;
  productId: string;
  default_coin: number;
  bonus_coin: number;
  price: number;
  language: string;
  os: string;
}

const Payments = () => {
  const { balance } = useAppSelector((state) => state.storage.session.globalUserSlice);
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, userName, session_token } = useContext(UserContext);
  const { executePreparePayment, executeGetChargeList } = useContext(PaymentContext);
  const [data, setData] = useState<DataItem[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const tossPayments = useRef(null);
  const query = new URLSearchParams(location.search);
  const [isLpointFormOpen, setIsLpointFormOpen] = useState(false);
  const [lpointBalance, setLpointBalance] = useState<number | null>(null);
  const [loadingDots, setLoadingDots] = useState('');

  useEffect(() => {
    if (lpointBalance === null) {
      const interval = setInterval(() => {
        setLoadingDots(prev => (prev.length >= 3 ? '' : prev + '.'));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [lpointBalance]);

  const openPopup = () => {
    setIsLpointFormOpen(true); 
  };

  const [getLPointBalance] = useGetLPointBalanceMutation();

  const handleLpointBalanceCheck = async (cardNumber: string, balance: number) => {
    setLpointBalance(balance);
  };

  useEffect(() => {
    const loadToss = async () => {
      // @ts-ignore
      tossPayments.current = await loadTossPayments("live_ck_AQ92ymxN34R0R9vaqXbArajRKXvd");
    };
    loadToss();
  }, [data]);

  useEffect(() => {
    if (userId && userId <= -1) {
      navigate("/login");
    } else {
      fetchChargeList();
    }
  }, [userId]);

  const fetchChargeList = async () => {
    try {
      const response = await executeGetChargeList();
      setData(response.data.list);
    } catch (error) {
      console.error("Failed to fetch charge list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentClick = () => {
    if (selectedProduct === null) {
      setConfirmationMessage("결제 금액을 선택해주세요.");
    } else if (!isAgreementChecked) {
      setConfirmationMessage("구매 진행에 동의해주세요.");
    } else {
      setIsLpointFormOpen(true);
    }
  };

  const requestPayment = async () => {
    try {
      const res = await executePreparePayment(session_token, selectedProduct!);
      if (res.data.code === 200) {
        // @ts-ignore
        const paymentResponse = await tossPayments.current.requestPayment("카드", {
          cardCompany: "lpoint",
          amount: data[selectedProduct!].price,
          orderId: res.data.data.orderId,
          orderName: `${data[selectedProduct!].default_coin} ContentCoin`,
          customerName: userName,
          successUrl: `${window.location.origin}/payments/complete`,
          failUrl: `${window.location.origin}/payments/fail`,
        });

        const confirmResponse = await fetch("https://api-client.allcomics.org/v1/payment/confirm", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session_token}`,
          },
          body: JSON.stringify(paymentResponse),
        });

        if (!confirmResponse.ok) {
          throw new Error("Payment confirmation failed");
        }

        setIsSuccessPopupOpen(true);
      }
    } catch (error) {
      console.error("Payment request error:", error);
    }
  };

  useEffect(() => {
    const fetchInitialBalance = async () => {
      if (session_token) {
        try {
          console.log("Fetching initial L.Point balance...");
          const result = await getLPointBalance({ token: session_token, cardNumber: '' }).unwrap();
          console.log("Initial L.Point balance result:", result);
          if (result && result.balance !== undefined) {
            setLpointBalance(result.balance);
          } else {
            console.error("Unexpected response format:", result);
            setLpointBalance(null);
          }
        } catch (error) {
          console.error("Failed to fetch initial L.Point balance:", error);
          setLpointBalance(null);
        }
      } else {
        console.log("No session token available for initial balance fetch");
      }
    };

    fetchInitialBalance();
  }, [session_token, getLPointBalance]);

  return (
    <div>
      <Header />

      {confirmationMessage && <ConfirmPopup text={confirmationMessage} setText={setConfirmationMessage} />}

    <div className="w-full flex justify-center">
    <div className="max-w-[1200px] w-full flex flex-col items-center pt-16">
    <div className="flex items-center justify-between w-full px-4 mb-4">
      <div className="flex items-center text-lg">
        <span className="text-xl font-bold">ContentCoin 충전</span>
        <span className="ml-2">내 보유 포인트 ContentCoin&nbsp;</span>
        <span className="font-bold text-alco-mint">{balance!.toLocaleString("ko-KR")}</span>
        <span className="ml-6">LPoint</span> {/* 여기서 ml-2를 ml-6으로 변경 */}
        <span className="font-bold text-alco-mint min-w-[100px]">
          {lpointBalance !== null 
            ? lpointBalance.toLocaleString("ko-KR") 
            : <span className="loading-text">조회 중{loadingDots}</span>
          }
        </span>
      </div>

      <button onClick={openPopup} className="open-popup-btn bg-alco-mint text-white py-2 px-3 rounded">
        L.Point 카드 추가
      </button>
    </div>

    <div className="w-full h-[4px] bg-[#eeeeee]" />

          {isLpointFormOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-md shadow-lg">
                <LPointCardForm 
                  onClose={() => setIsLpointFormOpen(false)} 
                  onBalanceCheck={handleLpointBalanceCheck}
                />
              </div>
            </div>
          )}

          <div className="w-full h-[4px] bg-[#eeeeee]" />

          <div className="w-full px-4">
            <div className="text-base text-[#666666] mb-4">충전하실 금액을 선택해주세요.</div>
            {isLoading ? (
              <Loading height="h-[396px]" />
            ) : (
              data.map((item, index) => (
                <div
                  key={index}
                  className={`flex items-center p-4 border rounded-[8px] cursor-pointer ${
                    selectedProduct === index ? "border-alco-mint" : "border-[#eeeeee]"
                  }`}
                  onClick={() => setSelectedProduct(index)}
                >
                  <CircleC className="w-[24px] h-[24px] mr-4" />
                  <div className="font-bold flex-grow">
                    {item.default_coin} ContentCoin <span className="text-alco-mint">+{item.bonus_coin}</span>
                  </div>
                  <div className="min-w-[95px] bg-alco-mint text-white rounded-[16px] px-4 py-2">
                    ￦{item.price.toLocaleString("ko-KR")}
                  </div>
                </div>
              ))
            )}
          </div>

          {selectedProduct !== null && (
            <div className="w-full px-4 my-4">
              <div className="text-base text-[#666666] mb-2">결제 금액을 확인해주세요.</div>
              <div className="flex items-center">
                <img src="/logocolor.png" alt="logo" className="w-[64px] h-[48px]" />
                <div className="ml-4">
                  <div className="font-bold">{data[selectedProduct].default_coin} ContentCoin</div>
                  <div className="text-xl font-bold text-alco-mint">
                    ￦{data[selectedProduct].price.toLocaleString("ko-KR")}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="w-full px-4 mt-4">
            <div className="font-bold text-lg">결제 상품 이용안내</div>
            <ul className="text-sm text-[#666666] list-disc ml-4">
              <li>대여권, 소장권을 구매한 작품은 구매 취소하거나 환불을 받을 수 없습니다.</li>
              <li>결제에 대한 자세한 문의는 고객지원 페지에서 문의해주시기 바랍니다.</li>
            </ul>

            <div
              className="flex items-center cursor-pointer mt-4"
              onClick={() => setIsAgreementChecked(!isAgreementChecked)}
            >
              <img
                src={isAgreementChecked ? "ico_check_on.png" : "ico_check_off.png"}
                alt="agreement checkbox"
                className="w-8 h-8 mr-2"
              />
              (필수) 위 내용을 확인하였으며 구매 진행에 동의합니다.
            </div>

            <button
              className="w-full bg-alco-mint text-white font-bold text-lg rounded-[8px] px-4 py-2 mt-4"
              onClick={handlePaymentClick}
            >
              결제요청
            </button>
          </div>
        </div>
      </div>

      {isLpointFormOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-100">
        </div>
      )}

      {isSuccessPopupOpen && <PaymentSuccessPopup isOpen={isSuccessPopupOpen} onClose={() => setIsSuccessPopupOpen(false)} />}
    </div>
  );
};


// 아래 CSS를 추가
const styles = `
  @keyframes ellipsis {
    0% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
    100% { content: ''; }
  }

  .loading-text::after {
    content: '';
    animation: ellipsis 2s infinite;
  }
`;

// 스타일을 페이지에 주입
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Payments;
