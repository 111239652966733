import { UserContext } from '@context/UserContext'; // UserContext import 추가
import React, { useContext, useState } from "react";
import { useGetLPointBalanceMutation } from "../../redux/services/payments";
import "./styles.css";

interface LpointCardFormProps {
  onClose: () => void;
  onBalanceCheck: (cardNumber: string, balance: number) => void; // 수정된 prop 타입
}

const LPointCardForm: React.FC<LpointCardFormProps> = ({ onClose, onBalanceCheck }) => {
  const [cardNumber, setCardNumber] = useState<string>(""); 
  const [balance, setBalance] = useState<number | null>(null); 
  const [error, setError] = useState<string | null>(null);
  const [getLPointBalance, { isLoading }] = useGetLPointBalanceMutation();

  const { session_token } = useContext(UserContext); // UserContext에서 session_token 가져오기

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ""); 
    if (value.length > 16) return;

    const formattedValue = value
      .replace(/(\d{4})(?=\d)/g, "$1 ")
      .replace(/(\d{4})(\d{4})(\d{4})(\d{0,4})/, "$1 $2 $3 $4");

    setCardNumber(formattedValue);

    // Check balance when card number is complete
    if (value.length === 16) {
      checkBalance(value);
    } else {
      setBalance(null);
      setError(null);
    }
  };

  const checkBalance = async (cardNum: string) => {
    if (!session_token) {
      console.error("세션 토큰이 없습니다.");
      setError("인증에 실패했습니다. 다시 로그인해주세요.");
      return;
    }

    try {
      console.log("Attempting to check balance with session_token:", session_token);
      const response = await getLPointBalance({ token: session_token, cardNumber: cardNum }).unwrap();
      console.log("Balance check response:", response);
      setBalance(response.balance);
      onBalanceCheck(cardNum, response.balance); // 카드 번호와 잔액을 전달
      setError(null);
    } catch (error) {
      console.error("잔액 조회 실패:", error);
      setError("잔액 조회에 실패했습니다.");
      setBalance(null);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onClose();
  };

  return (
    <main className="container">
      <section className="ui">
        <div className="container-left">
          <form id="credit-card" onSubmit={handleSubmit}>
            <div className="number-container">
              <label htmlFor="card-number">카드 번호</label>
              <input
                type="text"
                name="card-number"
                id="card-number"
                maxLength={19}
                placeholder="1234 5678 9101 1121"
                value={cardNumber}
                onChange={handleCardNumberChange}
                required
              />
            </div>

            <div className="infos-container">
              {isLoading && <p>잔액 조회 중...</p>}
              {error && <p className="error">{error}</p>}
              {balance !== null && <p>현재 잔액: {balance} 포인트</p>} {/* 잔액 표시 */}
            </div>
            <input type="submit" value="조회" id="add" />
          </form>
        </div>

        <div className="container-right">
          <div className="card">
            <div className="intern">
              <div className="card-header">
                <div className="card-title">Loving your Life</div>
                <div className="card-title">L.POINT</div>
                <div className="card-number">
                  <div className="number-vl">
                    {cardNumber || "1234 5678 9101 1121"}
                  </div>
                </div>

                <img className="chip" src="../chip.png" alt="chip" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LPointCardForm;
